import React, { Component } from "react";

const Loading = Loader.Loading;
const NoData = Loader.loadBaseComponent("NoData");
const Card = Loader.loadBusinessComponent("BaseLibComponents", "List");
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');

class FaceSnapshotRecord extends Component {
  constructor(props) {
    super(props);
    // this.infinitRef = React.createRef();
  }
  render() {
    let {
      dataList,
      loading,
      hasLoadMore,
      searchData,
      loadMore,
      forwardRef,
      goSnapshotDetail
    } = this.props;
    return (
      <div className="baselib-list-wrapper snapshot-wrapper">
        {!!dataList.length ? (
          <InfiniteScrollLayout
            itemWidth={176}
            itemHeight={290}
            pdWidth={140}
            data={dataList}
            hasLoadMore={hasLoadMore}
            hasBackTop={true}
            ref={forwardRef}
            loadMore={loadMore}
            renderItem={item => (
              <Card
                data={item}
                type= "face"
                goSnapshotDetail={goSnapshotDetail}
                searchData={searchData}
                personMap={false}
                detailModuleName="faceLibraryDetail"
                hasDetail={false}
              />
            )}
          />
        ) : loading ? (
          <Loading loading={loading} />
        ) : (
          <NoData />
        )}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <FaceSnapshotRecord {...props} forwardRef={ref} />);
