import React, { Component } from 'react';

// const ObjectMapPersonnelCard = Loader.loadModuleComponent(
//   'Card',
//   'ObjectMapPersonnelCard'
// );
const ObjectMapPersonnelCard = Loader.loadModuleComponent('ObjectMapPersonnelCard');

class Wrapper extends Component {
  render() {
    let {
      data,
      children,
      handleSimilarShow,
      onFollow
    } = this.props;
    let {portraitPictureUrl,aidPictureInfos,aid,address,personId,personName,isFocus,tags,recentAppearanceAddress,recentAppearanceTime} = data
    return (
      <div className="snapshot-record-container">
        <div className="personnel-list">
          <ObjectMapPersonnelCard
            data={data}
            imgUrl={
              portraitPictureUrl ||
              (aidPictureInfos && aidPictureInfos.length > 0 && aidPictureInfos[0].newestPictureUrl) || ''
            }
            aid={aid}
            address={address}
            personId={personId}
            onRelation={handleSimilarShow}
            onFollow={onFollow}
            personName={personName}
            isFocus={isFocus}
            tags={tags}
            lastAddress={recentAppearanceAddress}
            lastTime={recentAppearanceTime}
          />
        </div>
      </div>
    );
  }
}

export default Wrapper;
