import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import FaceSnapshotRecord from "./components/faceList";
import BodySnapshotRecord from "./components/bodyList";
import AccessSnapshotRecord from "./components/accessList";
import LeftContent from "./components/wrapper";
import "./index.less";

const Wrapper = Loader.loadBaseComponent("TwoColumnLayout");
const RefreshButton = Loader.loadBaseComponent("RefreshButton");
const SimilarModal = Loader.loadBusinessComponent(
  "ObjectMapPersonnelComponent",
  "SimilarModal"
);

@withRouter
@Decorator.businessProvider("tab")
@observer
class SnapshotRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [], // 展示数据
      data: {},
      aidList: [], // 人脸
      bodyList: [], //人体
      accList: [], // 门禁记录
      tabKey: 0,
      loading: true,
      inifityKey: Math.random(),
      similarVisible: false, //关联aid弹窗
      hasLoadMore: true,
      fresh:false,
      aidDetailOption: {
        startTime: moment(
          moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD 00:00:00")
        ).valueOf(),
        endTime: moment().valueOf(),
        limit: 200,
        offset: 0
      }
    };
    this.accessInfinitRef = React.createRef();
    this.bodyInfinitRef = React.createRef();
    this.faceInfinitRef = React.createRef();
  }

  async componentDidMount() {
    const { history } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    await LM_DB.get("parameter", id).then(res => {
      let data = res.data;
      this.setState(
        {
          data
        },
        () => {
          this.queryFocus(data);
          this.refresh(data);
          this.setState({ fresh:false });
        }
      );
    });
    SocketEmitter.on(SocketEmitter.eventName.updatePerson, this.updatePerson);
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updatePerson, this.updatePerson);
  }

  updatePerson = (personData = {}) => {
    let { data } = this.state;
    data.isFocus = personData.isFocus;
    this.setState({ data });
  };

  queryFocus = parms => {
    let { data } = this.state;
    Service.person.getFocusInfos(parms.focusInfoUrl).then(res => {
      res.data.map(v => {
        if (v.aid === data.aid) {
          data.isFocus = v.isFocus;
        }
      });
      this.setState({ data });
    });
  };

  updateList = () => {
    // TODO 待验证 大列表刷新未验证
    this.accessInfinitRef.current && this.accessInfinitRef.current.forceUpdateGrid();
    this.bodyInfinitRef.current && this.bodyInfinitRef.current.forceUpdateGrid();
    this.faceInfinitRef.current && this.faceInfinitRef.current.forceUpdateGrid();
  }

  refresh = async data => {
    //TODO 添加await 同步刷新数据 （修改 可能不是必要的  有待验证）
    await this.setState((prevState) => ({aidDetailOption:{...prevState.aidDetailOption,offset:0,limit:200},fresh:true}));
    if (data.hasAid) {
      await this.queryPloyDetailList(data, true);
      this.setState({ inifityKey: Math.random()});
      return;
    }
    if (data.personId) {
      await this.queryEntryList(data);
      this.setState({ inifityKey: Math.random()});
      return;
    }
    if (data.aid) {
      await this.queryAidList(data);
      this.setState({ inifityKey: Math.random()});
      return;
    }
  };

  loadMore = async () => {
    let { data, aidDetailOption, accList, tabKey, aidList, bodyList, hasLoadMore,
      fresh
    } = this.state;
    let list = [aidList, bodyList, accList];
    let totals = [aidList, bodyList, accList][tabKey].length;
    await this.setState({ hasLoadMore: false });
    if ((aidDetailOption.offset > totals - aidDetailOption.limit) || fresh) {
      this.setState({ hasLoadMore: true,fresh:false });
      return;
    }
    aidDetailOption.offset += aidDetailOption.limit;
    const res = await this.queryPloyDetailList({...data,...aidDetailOption});
    let dataList = res[tabKey].data.list;
    if (aidDetailOption.limit - dataList.length === 0) {
      hasLoadMore = true;
    } else {
      hasLoadMore = false;
    }
    list[tabKey] = list[tabKey].concat(dataList);
    await this.setState({
      loading: false,
      hasLoadMore,
      aidList: list[0] || [],
      bodyList: list[1]|| [],
      accList: list[2] || [],
      aidDetailOption,
    });
    this.updateList();
  };

  queryPloyDetailList = (data, first = false) => {
    const queryDetail = this.queryDetail({ aids: data.bindAids || [data.aid] || data.aids }, first),
      queryAccessRecords = this.queryAccessRecords(data.accessRecordsUrl);
    return Promise.all([
      Utils.catchPromise(queryDetail),
      Utils.catchPromise(queryAccessRecords)
    ]).then(res => {
      let [aidRes, bodyRes] = res[0];
      let accRes = res[1];
      let aidList = aidRes ? aidRes.data.list : [],
        bodyList = bodyRes ? bodyRes.data.list : [],
        accList = accRes ? accRes.data.list : [];
      this.setState({
        loading: false,
        aidList,
        bodyList,
        accList
      });
      return [aidRes,bodyRes,accRes];
    });
  };

  queryEntryList = data => {
    this.queryAccessRecords(data.accessRecordsUrl).then(res => {
      let list = res.data.list || [];
      this.setState({
        loading: false,
        dataList: list,
        accList: list,
      }, () => {
        this.updateList();
      });
    });
  };

  queryAidList = data => {
    this.queryDetail({ aids: [data.aid] }).then(res => {
      let aidList = res[0] ? res[0].data.list : [],
        bodyList = res[1] ? res[1].data.list : [];
      this.setState({
        loading: false,
        aidList,
        bodyList
      },() => this.updateList());
    });
  };
  queryDetail = (parms, first = false) => {
    let aidDetailOption = this.state.aidDetailOption;
    first ? parms.offset = 0 : aidDetailOption.offset;
    const option = Object.assign({}, aidDetailOption, parms);
    return Promise.all([
      Service.person.queryAidDetail(option),
      Service.person.queryBodiesByAids(option)
    ]).then(res => {
      this.setState({
        aidDetailOption: option
      });
      return res;
    });
  };

  queryAccessRecords = parms => {
    if(!parms) {
      return Promise.resolve();
    }
    let aidDetailOption = this.state.aidDetailOption;
    return Service.person.getPersonAccessRecords(parms, aidDetailOption).then(res => {
      res.data.list.forEach(v => {
        v.noFaceUrl = v.pictureUrl;
        v.faceUrl = v.pictureUrl;
        v.captureTime = v.openTime;
        v.personName = v.cardOwner;
        v.deviceName = v.address;
      })
      return res;
    });
  };

  sortList = (aidList = [], accList = []) => {
    let arr = JSON.parse(JSON.stringify(aidList));
    aidList = aidList.sort((a, b) => b.captureTime - a.captureTime);
    accList = accList.sort((a, b) => b.captureTime - a.captureTime);
    let DETAIL_LEN = aidList.length,
      ACC_LEN = accList.length,
      FIRSTDATA = null,
      LASTDATA = null;
    if (!!ACC_LEN && !!DETAIL_LEN) {
      FIRSTDATA = aidList[0];
      LASTDATA = aidList[DETAIL_LEN - 1];
      accList.forEach(v => {
        if (v.captureTime > FIRSTDATA.captureTime) {
          arr.unshift(v);
        }
        if (
          v.captureTime < FIRSTDATA.captureTime &&
          v.captureTime > LASTDATA.captureTime
        ) {
          arr.push(v);
        }
      });
      arr = aidList.sort((a, b) => b - a);
      return arr;
    } else {
      return arr.concat(accList);
    }
  };
  onFollow = (parms, event) => {
    event.stopPropagation();
    let { data } = this.state;
    let option = {
      isFocus: !data.isFocus
    };
    if (data.personId) {
      option.personId = data.personId;
    } else {
      option.aid = data.aid;
    }
    Service.person.setOrCancelFocus(option).then(() => {
      data.isFocus = !data.isFocus;
      SocketEmitter.emit(SocketEmitter.eventName.reverseUpdatePerson, data);
      this.setState({
        data
      });
    });
  };

  // 关联aid
  handleSimilarOK = aids => {
    const { personData } = this.state;
    if (aids.length === 0) {
      aids = [
        {
          aid: "",
          aidUrl: "",
          similarity: 0,
          type: 1
        }
      ];
    }
    let option = {
      personId: personData.personId,
      aidBindParams: aids
    };
    Service.person.addRelationVids(option).then(res => {
      this.setState({
        similarVisible: false
      });
    });
  };

  handleSimilarShow = () => {
    this.setState({ similarVisible: true });
  };
  // 关闭关联aid模态框
  handleSimilarCancel = () => {
    this.setState({ similarVisible: false });
  };
  changeTab = key => {
    this.setState({ tabKey: key });
  };
  //跳转详情
  goSnapshotDetail = (datas,type) => {
    let { tab } = this.props
    let { aidList,bodyList,accList,data } = this.state
    const searchData = {
      startTime: moment()
        .subtract(3, "d")
        .valueOf(),
      endTime: moment().valueOf(),
      timerTabsActive: 3
    };
    tab.openDetailPage({
      moduleName: 'objSnapshotDetail',
      value: datas.id,
      data: {
        id: datas.id
      },
      beforeAction: async () => {
        await window.LM_DB.add('parameter', {
          id: datas.id.toString(),
          data:datas,
          type,
          searchData,
          baseData:data,
          list:type === 'face' ? aidList : (type === 'body' ? bodyList : accList) 
        })
      }
    });
  }
  render() {
    let {
      data,
      loading,
      aidList,
      bodyList,
      accList,
      tabKey,
      hasLoadMore,
      similarVisible,
      inifityKey
    } = this.state;
    let total = [aidList, bodyList, accList][tabKey].length;
    const searchData = {
      startTime: moment()
        .subtract(3, "d")
        .valueOf(),
      endTime: moment().valueOf(),
      timerTabsActive: 3
    };
    const operations = (
      <div className="header-little-pagtion">
        <div className="header-info">
          共显示<span> {parseInt(total) || 0} </span>条数据
        </div>
        <RefreshButton
          className="header-button"
          onClick={() => this.refresh(data)}
        />
      </div>
    );
    return (
      <Wrapper
        className="snapshot-record-container"
        title="抓拍记录"
        renderLeft={
          <LeftContent
            data={data}
            onRelation={this.handleSimilarShow}
            onFollow={this.onFollow}
          />
        }
        extra={operations}
        tabs={[
          { label: "人脸记录", value: 0, icon: "icon-S_Bar_Face" },
          { label: "人体记录", value: 1, icon: "icon-S_Bar_Body" },
          { label: "门禁记录", value: 2, icon: "icon-S_Point_Door" }
        ]}
        defaultActiveKey={0}
        getActiveKey={this.changeTab}
      >
        <div className="baselib-list-wrapper snapshot-wrapper">
          {similarVisible && (
            <SimilarModal
              visible={similarVisible}
              onOk={this.handleSimilarOK}
              onCancel={this.handleSimilarCancel}
              data={data}
            />
          )}
          {tabKey === 0 && (
            <FaceSnapshotRecord
              loading={loading}
              dataList={aidList}
              ref={this.faceInfinitRef}
              goSnapshotDetail={this.goSnapshotDetail}
              hasLoadMore={hasLoadMore}
              key={inifityKey}
              loadMore={this.loadMore}
              searchData={searchData}
            />
          )}
          {tabKey === 1 && (
            <BodySnapshotRecord
              loading={loading}
              ref={this.bodyInfinitRef}
              dataList={bodyList}
              key={inifityKey}
              goSnapshotDetail={this.goSnapshotDetail}
              hasLoadMore={hasLoadMore}
              loadMore={this.loadMore}
              searchData={searchData}
            />
          )}
          {tabKey === 2 && (
            <AccessSnapshotRecord
              loading={loading}
              dataList={accList}
              ref={this.accessInfinitRef}
              key={inifityKey}
              goSnapshotDetail={this.goSnapshotDetail}
              hasLoadMore={hasLoadMore}
              loadMore={this.loadMore}
              searchData={searchData}
            />
          )}
        </div>
      </Wrapper>
    );
  }
}

export default SnapshotRecord;
